<template>
  <div class="w-full relative">
    <PromoAndRecs
      :remove-img-overlay="removeImgOverlay"
      :content-colour="contentColour"
      :data="promoData"
      :images="getImages"
      :heading-types="headingTypes"
      :pathways="pathwaysAndRecsData"
      :is-loading="isLoading"
      :alt-background-colour="altBackgroundColour"
      :variant="variant"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { getContainerItemContent } from "@bloomreach/spa-sdk";
import PromoAndRecs from "~/components/promo-and-recs/PromoAndRecs.vue";
import PathwaysAndRecsMixin from "~/components/product-recommendations/mixins/PathwaysAndRecsMixin";
import { globalComponentProperties } from "~/composables/globalComponentProperties";
import { getDeviceImages } from "~/utils/bloomreach/images";

export default {
  name: "DealsCategoryPage",
  components: { PromoAndRecs },
  mixins: [PathwaysAndRecsMixin],
  props: {
    component: {
      type: Object,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // Return component properties from bloomreach SDK
    const properties = computed(() => props.component.getParameters());
    // Deconstruct computed style properties from composable
    const { contentColour } = globalComponentProperties(properties, props.page);

    return {
      properties,
      contentColour,
    };
  },
  data() {
    return {
      pathwaysAndRecsData: {},
      isLoading: true,
    };
  },
  computed: {
    data() {
      return getContainerItemContent(this.component, this.page);
    },
    promoData() {
      return {
        title: this.data.title,
        promoTitle: this.data.titleBodyImage.title,
        body: this.data.titleBodyImage.body,
        href: this.data.titleBodyImage.href,
        image: {
          altTag: this.data.titleBodyImage.title,
          imageUrl: this.data.titleBodyImage.backgroundImage.imageUrl,
          imageUrlTablet:
            this.data.titleBodyImage.backgroundImage.imageUrlTablet,
          imageUrlMobile:
            this.data.titleBodyImage.backgroundImage.imageUrlMobile,
        },
        pathwaysCtaName: this.data?.pathwaysCtaName || this.$t("viewDeal"),
        cta: this.data.cta,
      };
    },
    altBackgroundColour() {
      return this.properties.altBackgroundColour;
    },
    removeImgOverlay() {
      return this.properties.removeImgOverlay;
    },
    variant() {
      return this.properties.style;
    },
    headingTypes() {
      return {
        heading: this.properties.headingType,
        subHeading: this.properties.subHeadingType,
      };
    },
    getImages() {
      return getDeviceImages(this.promoData.image);
    },
  },
  async mounted() {
    this.pathwaysAndRecsData = await this.getPathwaysAndRecs(
      this.data.pathways
    );

    this.isLoading = false;
  },
};
</script>
